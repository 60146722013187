import { store, ThriveApplication } from '@thriveglobal/thrive-web-core'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import { Provider } from 'react-redux'
import Router from './routes'

dayjs.extend(weekday)
dayjs.extend(utc)

const loadi18n = (lang: string) =>
    fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)

export default function Root(props) {
    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            commitHash={process.env.GIT_REF}
            apolloClientOptions={{
                name: 'thrive-web-calls',
                cacheOptions: {}
            }}
        >
            <Provider store={store}>
                <section>
                    <Router />
                </section>
            </Provider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
