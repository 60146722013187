export const AUTH_POPUP_TIMEOUT = 5000

export const SSO_QUERY_PARAM = 'sso'

export const AGENT_EMAIL_QUERY_PARAM = 'agentEmail'

export const OAUTH_ENABLED_QUERY_PARAM = 'keycloakAuth'

export const AUTH_TYPE_QUERY_PARAM = 'auth'

export const PARTNER_LOGIN_DATE = 'genesysPartnerLoginDate'

export const PARTNER_LOGIN_EXPIRATION = 10 // days

export enum AuthenticationMethod {
    Genesys = 'genesys',
    Partner = 'partner',
    Keycloak = 'keycloak'
}

export enum GenesysRegion {
    ApNortheast_1 = 'AP_NORTHEAST_1',
    ApNortheast_2 = 'AP_NORTHEAST_2',
    ApSoutheast_2 = 'AP_SOUTHEAST_2',
    ApSouth_1 = 'AP_SOUTH_1',
    CaCentral_1 = 'CA_CENTRAL_1',
    EuCentral_1 = 'EU_CENTRAL_1',
    EuWest_1 = 'EU_WEST_1',
    EuWest_2 = 'EU_WEST_2',
    UsEast_1 = 'US_EAST_1',
    UsEast_2 = 'US_EAST_2',
    UsWest_2 = 'US_WEST_2',
    Wiremock = 'WIREMOCK'
}
