import {
    captureException,
    captureMessage,
    addMetadata
} from '@thriveglobal/thrive-web-core'
import { useCallback, useMemo } from 'react'

export type UseTracingResult = {
    setTag: (tag: string, value: string) => void
    captureException: (error: Error, additionInfo?: string | Object) => void
    captureMessage: (message: string, additionInfo?: string | Object) => void
}

function useTracing(): UseTracingResult {
    const handleCaptureException = useCallback(
        (error: Error, additionInfo?: string | Object) => {
            console.error(error, additionInfo)
            captureException(
                error,
                typeof additionInfo === 'string'
                    ? { message: additionInfo }
                    : additionInfo
            )
        },
        []
    )

    const handleCaptureMessage = useCallback(
        (message: string, additionInfo?: string | Object) => {
            console.log(message, additionInfo)
            captureMessage(
                message,
                typeof additionInfo === 'string'
                    ? { message: additionInfo }
                    : additionInfo
            )
        },
        []
    )

    const handleSetTag = useCallback(
        (tag: string, value: string) => {
            try {
                console.info(`${tag}: ${value}`)
                addMetadata(tag, value)
            } catch (error) {
                handleCaptureException(
                    error,
                    `Failed to add tracking label for ${tag} with value ${value}`
                )
            }
        },
        [handleCaptureException]
    )

    return useMemo(
        () => ({
            setTag: handleSetTag,
            captureMessage: handleCaptureMessage,
            captureException: handleCaptureException
        }),
        [handleSetTag, handleCaptureMessage, handleCaptureException]
    )
}

export default useTracing
