/* eslint-disable */
import {
    authSetAccessToken,
    captureException,
    store,
    userUpdateCompanyId,
    userUpdateUserId
} from '@thriveglobal/thrive-web-core'
import jwt from 'jwt-decode'
import { ApiClientClass, Models } from 'purecloud-platform-client-v2'
import { Dispatch } from 'redux'
import { getItem } from '../../../sessionStorage'
import config from '../config'
import { GENESYS_ACCESS_TOKEN } from '../GenesysContext'

import { GenesysRegion } from '../GenesysAuthProvider/constants' // reference constants file directly to avoid circular dependency
import { PlatformClient } from '../types'

export type IdentityData = {
    companyId: string
    provider: {
        id: string
    }
    uid: string
    region: GenesysRegion
}

export interface ThriveJWTPayload {
    sub: string
    uid: string
    companyId: string
    provider: {
        id: string
        type: string
        role: string
    }
    scope: string
    iss: string
    subscriptionId: string
    exp: number
    version: string
    iat: number
}

const doImplicitGrant = (client: ApiClientClass, redirect: string) => {
    client.setEnvironment(getGenesysEnvironment())
    client.setPersistSettings(true)
    return client.loginImplicitGrant(
        process.env.GENESYS_BROWSER_CLIENT_ID,
        redirect
    )
}

const getExisting = (platformClient: PlatformClient) => {
    const authorizationApi = new platformClient.AuthorizationApi()
    const authOpts = {
        name: config.prefix + '*', // Wildcard to work like STARTS_WITH
        userCount: false
    }

    return authorizationApi.getAuthorizationRoles(authOpts)
}

const removeAll = (platformClient: PlatformClient) => {
    const authorizationApi = new platformClient.AuthorizationApi()
    return getExisting(platformClient).then((roles) => {
        const del_role = []
        if (roles.total > 0) {
            roles.entities
                .map((r) => r.id)
                .forEach((rid) => {
                    del_role.push(authorizationApi.deleteAuthorizationRole(rid))
                })
        }
        return Promise.all(del_role)
    })
}

const createAll = (
    platformClient: PlatformClient
): Promise<Record<string, Models.DomainOrganizationRole>> => {
    const authorizationApi = new platformClient.AuthorizationApi()
    const rolePromises = []
    const roleData = {} // Object of "rolename": (Role Object)

    config.provisioningInfo['role'].forEach((role) => {
        const roleBody = {
            name: config.prefix + role.name,
            description: role.description,
            permissionPolicies: role.permissionPolicies,
            permissions: role.permissions
        }

        rolePromises.push(
            authorizationApi.postAuthorizationRoles(roleBody).then((data) => {
                roleData[role.name] = data
            })
        )
    })
    return Promise.all(rolePromises).then(() => roleData)
}

const configure = (
    platformClient: PlatformClient,
    installedData,
    userId: string
) => {
    const authorizationApi = new platformClient.AuthorizationApi()
    // Assign the role to the user
    // Required before you can assign the role to an Auth Client.
    const promiseArr = []
    const roleData = installedData

    const roles = config.provisioningInfo['role']
    const opts = {
        subjectType: 'PC_USER' // String | what the type of the subjects are (PC_GROUP, PC_USER or PC_OAUTH_CLIENT)
    }

    const body = {
        subjectIds: [userId],
        divisionIds: ['*']
    }

    roles.forEach((role) => {
        promiseArr.push(
            authorizationApi
                .putAuthorizationRoleUsersAdd(roleData[role.name].id, [userId])
                .then((data) => {
                    // TO DO: rm when done with POC
                    // eslint-disable-next-line no-console
                    console.log(
                        'Assigned ' + roleData[role.name].name + ' to user'
                    )
                })
        )

        promiseArr.push(
            authorizationApi
                .postAuthorizationRole(roleData[role.name].id, body, opts)
                .then((data) => {
                    // TO DO: rm when done with POC
                    // eslint-disable-next-line no-console
                    console.log(
                        'Assigned divisions for ' +
                            roleData[role.name].name +
                            ' to user'
                    )
                })
        )
    })

    return Promise.all(promiseArr)
}

const exchangeGenesysToken = async (
    genesysClient: ApiClientClass
): Promise<IdentityData> => {
    const cachedToken = getItem(GENESYS_ACCESS_TOKEN)
    const environment = getClientEnvironment(genesysClient)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${cachedToken}`
        },
        body: JSON.stringify({
            query: 'mutation Genesys($region: GenesysRegion!) { logMeWith { genesys(region: $region) {     token    }  }}',
            variables: { region: `${environment}` }
        })
    }

    return fetch(process.env.THRIVE_IDENTITY_URL, requestOptions)
        .then((response) => {
            return response.json().then((responseJson) => {
                const dispatch: Dispatch = store.dispatch

                dispatch(
                    authSetAccessToken(
                        responseJson.data.logMeWith.genesys.token
                    )
                )

                const identityData = jwt<IdentityData>(
                    responseJson.data.logMeWith.genesys.token
                )

                dispatch(userUpdateUserId(identityData?.uid))
                dispatch(userUpdateCompanyId(identityData?.companyId))

                return identityData
            })
        })
        .catch((err) => {
            captureException(
                err,
                `Genesys Token exchange failed for {'genesysToken': '${cachedToken}', 'region': '${environment}'}`
            )
            return jwt<IdentityData>(null)
        })
}

const exchangeGenesysTokenFromLocalStorage = async (
    genesysClient: ApiClientClass
): Promise<IdentityData> => {
    const cachedToken = localStorage.getItem(GENESYS_ACCESS_TOKEN)
    const token = JSON.parse(cachedToken).accessToken
    const environment = getClientEnvironment(genesysClient)
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            query: 'mutation Genesys($region: GenesysRegion!) { logMeWith { genesys(region: $region) {     token    }  }}',
            variables: { region: `${environment}` }
        })
    }

    // TODO: refactor to re-use code from exchangeGenesysToken and handle gql errors properly
    return fetch(process.env.THRIVE_IDENTITY_URL, requestOptions)
        .then((response) => {
            return response.json().then((responseJson) => {
                const dispatch: Dispatch = store.dispatch

                dispatch(
                    authSetAccessToken(
                        responseJson.data.logMeWith.genesys.token
                    )
                )

                const identityData = jwt<IdentityData>(
                    responseJson.data.logMeWith.genesys.token
                )

                dispatch(userUpdateUserId(identityData?.uid))
                dispatch(userUpdateCompanyId(identityData?.companyId))

                return identityData
            })
        })
        .catch((err) => {
            captureException(
                err,
                `Genesys Token exchange failed for {'genesysToken': '${token}', 'region': '${environment}'}`
            )
            return jwt<IdentityData>(null)
        })
}

const getClientOrgId = async (platformClient: PlatformClient) => {
    const tokenApi = new platformClient.TokensApi()

    const response = await tokenApi.getTokensMe()

    return response.organization.id
}

const getClientEnvironment = (genesysClient: ApiClientClass): GenesysRegion => {
    return reverseRegionMap[genesysClient.config.environment]
}

const getGenesysEnvironment = (): GenesysRegion => {
    // Get environment from query params
    const urlParams = new URLSearchParams(window.location.search)
    const queryEnv = urlParams.get(config.genesysCloudEnvironmentQueryParam)
    let tempEnv = queryEnv

    if (!tempEnv) {
        const scriptId = urlParams.get(config.scriptQueryParam)
        const queueId = urlParams.get(config.queueQueryParam)

        const customerConfig = customerRegionMap.filter(function (v, i) {
            return v['scriptId'] === scriptId && v['queueId'] === queueId
        })

        if (customerConfig.length > 0) {
            tempEnv = customerConfig[0].environment
        }
    }

    const cachedEnv = localStorage.getItem(config.appName + ':env')

    if (!tempEnv && cachedEnv && cachedEnv !== 'null') {
        tempEnv = cachedEnv
    }

    const pcEnvironment = tempEnv || config.defaultPcEnvironment

    localStorage.setItem(config.appName + ':env', pcEnvironment)

    return pcEnvironment as GenesysRegion
}

const reverseRegionMap: Record<string, GenesysRegion> = {
    'mypurecloud.com': GenesysRegion.UsEast_1, //'US_EAST_1',
    'mypurecloud.ie': GenesysRegion.EuWest_1, //'EU_WEST_1',
    'mypurecloud.com.au': GenesysRegion.ApSoutheast_2, //'AP_SOUTHEAST_2',
    'mypurecloud.jp': GenesysRegion.ApNortheast_1, // 'AP_NORTHEAST_1',
    'mypurecloud.de': GenesysRegion.EuCentral_1, // 'EU_CENTRAL_1',
    'usw2.pure.cloud': GenesysRegion.UsWest_2, //'US_WEST_2',
    'cac1.pure.cloud': GenesysRegion.CaCentral_1, // 'CA_CENTRAL_1',
    'apne2.pure.cloud': GenesysRegion.ApNortheast_2, // 'AP_NORTHEAST_2',
    'euw2.pure.cloud': GenesysRegion.EuWest_2, //'EU_WEST_2',
    'aps1.pure.cloud': GenesysRegion.ApSouth_1, //'AP_SOUTH_1',
    'use2.us-gov-pure.cloud': GenesysRegion.UsEast_2 // 'US_EAST_2'
}

const customerRegionMap = [
    {
        organizationName: 'Providence Health',
        organizationId: '852b4e0a-458f-4190-8b01-6b84852c591a',
        environment: 'usw2.pure.cloud',
        queueId: 'b9e1112e-72ca-4288-adea-0fbd642ec4e0',
        scriptId: 'e099d8de-f9f7-4390-8353-36ede7e0a981',
        region: 'production'
    },
    {
        organizationName: 'Greensky DEV',
        organizationId: '88a3993d-6cb3-4f46-938e-bc144761b373',
        environment: 'mypurecloud.com',
        queueId: 'b15eb993-432a-4036-82e3-63071c254d25',
        scriptId: '37cadca3-8467-4304-a0a9-9207af3eb3f9',
        region: 'staging'
    },
    {
        organizationName: 'Greensky',
        organizationId: '6779f5fe-4e5f-4e88-a703-8cabce209266',
        environment: 'mypurecloud.com',
        queueId: '90504248-9f78-4319-8651-82acf931d329',
        scriptId: 'bd79f9e5-719d-4374-8103-94fdd9cbabef',
        region: 'production'
    },
    {
        organizationName: 'Thrive',
        organizationId: 'de1abbae-c697-457e-8341-717642f1adc0',
        environment: 'usw2.pure.cloud',
        queueId: 'fbc43791-a8e2-4fca-9a2c-1e3058424ef8',
        scriptId: 'c8fae732-cea5-4c27-81d2-77d08bae6d85',
        region: 'staging'
    },
    {
        organizationName: 'Best Buy Health',
        organizationId: '93c11f18-dfc0-4e51-ac63-579b490a6960',
        environment: 'usw2.pure.cloud',
        queueId: '8b3e1673-a350-4dd6-a6c7-5417a54c0a26',
        scriptId: 'b60f6f7a-9270-4c7d-9858-c98350725a09',
        region: 'production'
    },
    {
        organizationName: 'Living Spaces',
        organizationId: '04ebae48-4a47-48ce-8578-b0f3e8b4687e',
        environment: 'usw2.pure.cloud',
        queueId: 'b16e77bd-f118-407a-9077-f15990b5e421',
        scriptId: '97d46018-06d0-4ec6-b4c8-35ad8d48d512',
        region: 'production'
    },
    {
        organizationName: 'Enbridge',
        organizationId: '6009c91b-57df-4813-91a0-0b469e70d699',
        environment: 'cac1.pure.cloud',
        queueId: 'afe4efa1-78cb-4533-bebb-760d879d0e53',
        scriptId: '44cb77cf-018c-465e-8dae-d798fc90914a',
        region: 'production'
    },
    {
        organizationName: 'Alberta Health',
        organizationId: '8b558848-2033-4267-bbf1-bbd10de1e714',
        environment: 'cac1.pure.cloud',
        queueId: '3d9b98ac-5dd9-4ee0-8e4d-9c24040b2e03',
        scriptId: '08c69368-04a6-483b-b164-d9b492a45e0f',
        region: 'production'
    },
    {
        organizationName: 'AIG',
        organizationId: 'ad4fc484-e0f4-44e8-95fc-7a610731d98e',
        environment: 'mypurecloud.com',
        queueId: 'f1542910-c31c-41c0-b76f-75b99e93363e',
        scriptId: 'feeabaaa-66f5-4803-89f8-b4dea10d8079',
        region: 'production'
    }
]

const provisioningInfoKey = 'role'

const hashCode = (str: string) => {
    let hash = 0
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0 // Convert to 32bit integer
    }
    return hash
}

export {
    configure,
    createAll,
    doImplicitGrant,
    exchangeGenesysToken,
    exchangeGenesysTokenFromLocalStorage,
    getClientEnvironment,
    getClientOrgId,
    getExisting,
    getGenesysEnvironment,
    hashCode,
    provisioningInfoKey,
    removeAll
}
