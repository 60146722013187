import { handleRedirectFromLogin } from 'genesys-cloud-client-auth'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CenteredProgress from '../components/CenteredProgress'
import { CallsPlatformType } from '../constants/callsPlatformType'
import { withPageFallback } from '../fallbacks/PageFallback'
import useTracing from '../hooks/useTracing'

export type ClientAuthProps = {}

function ClientAuth(props: ClientAuthProps): JSX.Element {
    const { captureException, captureMessage } = useTracing()
    const location = useLocation()

    useEffect(() => {
        if (location.hash.indexOf('state=gc-ca_') > -1) {
            captureMessage(
                'We are returning from the redirect and writing to storage'
            )
            try {
                handleRedirectFromLogin()
            } catch (e) {
                captureException(e, 'Error handling redirect from login')
                window.close()
                return
            }

            // fallback in case the redirect doesn't close the window
            setTimeout(() => {
                window.close()
            }, 1000)
        }
    }, [captureException, captureMessage, location.hash])

    return <CenteredProgress />
}

const ClientAuthWithFallback = withPageFallback(React.memo(ClientAuth), {
    pageName: 'GenesysClientAuthPage',
    platformType: CallsPlatformType.Genesys
})

export default ClientAuthWithFallback
